import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { useMutation, useQuery } from '@apollo/client';
import {
  ACTIVATE_DEVICE,
  DEACTIVATE_DEVICE,
  DELETE_DEVICE,
  DEVICE_DATA,
  M_DEVICE_ACTIVATE,
  TOGGLE_DEVICE_SUBSCRIPTION,
  UPDATE_DEVICE_DATA,
  patient,
} from '../../../data/queries/live';

import {
  STATUS_DEVICE_ACTIVE,
  STATUS_DEVICE_PENDING,
  STATUS_DEVICE_DEACTIVATED,
  STATUS_DEVICE_INCOMPLETE,
  DF_DD_MMM_YYYY_HH_COL_SMSM,
  DD_MMM_YYYY,
  DEVICE_MODES,
  DEVICE_APPLICATION_OPTIONS,
  getDeviceStatusFromData,
} from '../../../types/types';
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  Slider,
  Typography,
} from '@mui/material';
import BTextField from '../../common/BTextField';
import BButton from '../../common/BButton';
import BDropDown from '../../common/BDropDown';
import BToastMessage from '../../common/ToastMessage';
import BSaveCancel from '../../common/BSaveCancel';
import BProgress from '../../common/BProgress';
import messageFormat from '../../../utility/messageToUser.json';
import {
  generateID,
  getPrettyDate,
  getDiffFromActivation,
  calculateWearTimeRemaining,
  dataReceivedExceedsThreshold,
} from '../../../utility/utilityFunctions';
import BConfirm from '../../common/BConfirm';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SyncIcon from '@mui/icons-material/Sync';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useNavigate } from 'react-router-dom';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import BSpinner from '../../common/BSpinner';
import { useSendAuditTrailLogMessage } from '../../../data/queries/auditTrailLogMessage';

interface IProps {
  gotoHome: () => void;
}

const PatientRegistrationDevice = (props: IProps) => {
  const location = useLocation(); // get this page's URL
  const patientId = location.pathname.split('/').pop(); // extract patientId

  const [activationStatusIndex, setActivationStatusIndex] = useState(0); // 0/1/2/3
  const [activationTime, setActivationTime] = useState<number | null>(null);
  const [select] = useMutation(M_DEVICE_ACTIVATE);
  const [update] = useMutation(UPDATE_DEVICE_DATA);

  const { loading, error, data, refetch } = useQuery(DEVICE_DATA, {
    variables: { patientId: patientId },
    fetchPolicy: 'network-only',
  });

  const [activateDevice] = useMutation(ACTIVATE_DEVICE);
  const [deactivateDevice] = useMutation(DEACTIVATE_DEVICE);

  const [showSavedChangesToast, setShowSavedChangesToast] = useState(false);
  const [unsaveConfirm, setUnsaveConfirm] = useState({ open: false });
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false });
  const [deactivateDeviceConfirm, setDeactivateDeviceConfirm] = useState({
    open: false,
    data: '',
  });

  const [initialValues, setInitialValues] = useState({
    device_id: '',
    activation_status: 'Uninitialized',
    prescribing_provider: '',
    implantId: '',
    referring_provider: '',
    prescribed_wear_time_days: 30,
    application: '',
    last_transmission_timestamp: null,
    battery_level_percentage: 100,
    recording_started_at: null,
    type: 'mct',
  });

  const [updateLiveURL, liveURL = { data }] = useMutation(
    TOGGLE_DEVICE_SUBSCRIPTION
  );
  const [deleteDevice] = useMutation(DELETE_DEVICE);
  const formRef = useRef(null);

  const navigate = useNavigate();
  const USER_ACCESS = React.useContext(AccessPermissionsContext)?.access;
  const USERNAME = React.useContext(AccessPermissionsContext)?.name;
  const { sendAuditMessage } = useSendAuditTrailLogMessage();
  const { canRegisterDevice } = USER_ACCESS;
  const gotoHome = () => {
    navigate('/events');
  };

  const handleDeactivateDevice = values => {
    values['deviceId'] = data.patient.devices[0].id;

    // Deactivate the device
    deactivateDevice({
      variables: { deviceId: data.patient.devices[0].id, patientId: patientId },
    }).then(res => {
      refetch().then(refetchResult => {
        setActivationStatusIndex(
          getDeviceStatusFromData[
            refetchResult.data.patient.devices[0].activation_status
          ]
        );
      });
      sendAuditMessage({ action: "RESOURCE_UPDATE", phi_flag: true,
        description: `Deactivated device: ${data.patient.devices[0].deviceId}` });
    }).catch(error => {
      sendAuditMessage({ action: "RESOURCE_UPDATE_FAILURE", phi_flag: true,
        description: `Failed to deactivate device: ${data.patient.devices[0].deviceId} due to error ${error}` });
    })
  };

  const handleActivateDevice = values => {
    if (activationStatusIndex !== STATUS_DEVICE_PENDING) return; // only Pending devices can be activated

    values['deviceId'] = data.patient.devices[0].id;
    // Activate the device
    activateDevice({
      variables: {
        ...values,
        ...{ patientId: patientId, test_override: true },
      },
    }).then(result => {
      const deviceActivationTime = getPrettyDate(
        result.data.activateDevice.timestamp, // timestamp is already in ISO format
        DF_DD_MMM_YYYY_HH_COL_SMSM,
        true
      );
      setActivationTime(deviceActivationTime);
      refetch().then(() => {
        // refetch the data
        setActivationStatusIndex(
          getDeviceStatusFromData[
            result.data.activateDevice.device.activation_status
          ]
        );
      });
    }); // end of then
  };

  useEffect(() => {
    if (!data?.patient?.devices?.length) return;

    setActivationStatusIndex(
      getDeviceStatusFromData[data.patient.devices[0].activation_status]
    );
    setInitialValues({
      device_id: data.patient.devices[0].id,
      activation_status: data.patient.devices[0].activation_status,
      prescribing_provider: data.patient.devices[0].prescribing_provider || '',
      implantId: data.patient.devices[0].deviceId || '',
      referring_provider: data.patient.devices[0].referring_provider || '',
      prescribed_wear_time_days:
        data.patient.devices[0].prescribed_wear_time_days || 30,
      application: data.patient.devices[0].application || '',
      last_transmission_timestamp:
        data.patient.devices[0].deviceStatus[0]?.last_transmission_timestamp ||
        null,
      battery_level_percentage:
        data.patient.devices[0].deviceStatus[0]?.battery_level_percentage,
      recording_started_at:
        data.patient.devices[0].deviceStatus[0]?.recording_started_at,
      type: data.patient.devices[0].type == "unregistered" ?
        DEVICE_MODES[0].value : // default to MCT before Save happens
        data.patient.devices[0].type // whatever is returned
    });

    if (data.patient.devices[0].deviceActivations.length > 0) {
      setActivationTime(data.patient.devices[0].deviceActivations[0].timestamp);
    } else {
      setActivationTime(null);
    }
    sendAuditMessage({ action: "DATA_ACCESS", phi_flag: true,
      description: `Loaded device page: ${data.patient.devices[0].deviceId}` });
  }, [data]);

  if (loading || isEmpty(initialValues))
    return <BSpinner text={'Loading Device information...'} />;
  if (error) <p> Error occurred, please contact the Support Team! </p>;

  return (
    <>
      <BConfirm
        title={
          messageFormat.patientRegistration.patientProfile.confirmUnSavedChanges
        }
        open={unsaveConfirm.open}
        setOpen={setUnsaveConfirm}
        onConfirm={gotoHome}
        severity='warning'
      />
      <BConfirm
        title={'The device details will be deleted forever.'}
        children={'Do you still want to proceed?'}
        open={deleteConfirm.open}
        setOpen={setDeleteConfirm}
        onConfirm={() => {
          deleteDevice({
            variables: { deviceId: data.patient.devices[0].id },
          }).then(() => {
            sendAuditMessage({ action: "RESOURCE_DELETION", phi_flag: true,
              description: `Deleted device: ${data.patient.devices[0].deviceId}` });
            props.gotoHome();
          });
        }}
        severity='warning'
      />
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize // needed to update initialValues https://github.com/jaredpalmer/formik/issues/811#issuecomment-445437051
        validationSchema={yup.object().shape({
          prescribing_provider: yup
            .string()
            .required('Prescribing Provider is required')
            .max(255, 'Maximum 255 characters'),
          implantId: yup
            .string()
            .required('Device Serial Number is required')
            .matches(
              /^[A-HJ-KMNPR-Z0-9]{5}-[A-HJ-KMNPR-Z0-9]{4}[89ABCDEF](?:-T)?$/,
              'Device Serial Number is not valid'
            ),
          // implantId uniqueness check is handled in onSubmit
          referring_provider: yup
            .string()
            .required('Referring Provider is required')
            .max(255, 'Maximum 255 characters'),
          application: yup
            .string()
            .required('Please select application location'),
        })}
        onSubmit={(values: any, actions) => {
          (async () => {
            // get the current type from the dropdown, even default

            try {
              await update({
                variables: {
                  deviceId: data.patient.devices[0].id,
                  implantId: values.implantId,
                  prescribing_provider: values.prescribing_provider,
                  referring_provider: values.referring_provider,
                  patientId: patientId,
                  prescribed_wear_time_days: parseInt(
                    values.prescribed_wear_time_days
                  ),
                  type: values.type,
                  application: values.application,
                },
              });
              await refetch().then(result => {
                // refetch data so form reflects backend
                values.application = result.data.patient.devices[0].application;
                setActivationStatusIndex(
                  getDeviceStatusFromData[
                    result.data.patient.devices[0].activation_status
                  ]
                );
                sendAuditMessage({ action: "DATA_SAVE", phi_flag: true,
                  description: `Updated device: ${result?.data?.patient?.devices[0]?.deviceId}` });
              });

              setShowSavedChangesToast(true);
              actions.resetForm({ values: values });
              setInitialValues(values);
            } catch (err) {
              // throw an error if we find a duplicate device ID in DB
              const unique = err.graphQLErrors?.some(
                gqlErr => gqlErr.extensions.code === 'UNIQUE_VIOLATION'
              );
              if (unique)
                actions.setFieldError(
                  'implantId',
                  'This device ID is already in use'
                );

              console.error('Error submitting form:', err);
              sendAuditMessage({ action: "DATA_SAVE_FAILURE", phi_flag: true,
                description: `Failed to update device: ${values.implantId}` });
            }
          })();
        }}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          dirty,
          values,
        }) => (
          <>
            <BConfirm
              title='Deactivating the device will stop all data transmission and event notifications'
              open={deactivateDeviceConfirm.open}
              setOpen={setDeactivateDeviceConfirm}
              onConfirm={() => {
                setDeactivateDeviceConfirm({ open: false, data: '' });
                handleDeactivateDevice(values);
              }}
              okCaption='deactivate'
              cancelEvent={() => {
                setDeactivateDeviceConfirm({ open: false, data: '' });
              }}
              saveId={generateID('btn', 'pr_device', 'confirm_deactivation')}
              cancelId={generateID('btn', 'pr_device', 'cancel_deactivation')}
              okDisabled={deactivateDeviceConfirm.data !== values['implantId']}
              severity='warning'
            >
              {
                <div>
                  <Typography>
                    Enter the device serial number to deactivate:{' '}
                  </Typography>
                  <p>
                    <BTextField
                      id={generateID('input', 'pr_device', 'confirm_device_id')}
                      name='deactivateData'
                      label='Device SN'
                      shrink='false'
                      value={deactivateDeviceConfirm.data}
                      handleChange={e => {
                        setDeactivateDeviceConfirm({
                          ...deactivateDeviceConfirm,
                          ...{ data: e.target.value },
                        });
                      }}
                      startAdornment={undefined}
                    />
                  </p>
                </div>
              }
            </BConfirm>
            {canRegisterDevice ? (
              <Grid container spacing={2}>
                <Grid item md={12} lg={10}>
                  <BToastMessage
                    id={generateID(
                      'toast',
                      'pr_device',
                      'notification_msg_top'
                    )}
                    open={showSavedChangesToast}
                    close={() => {
                      setShowSavedChangesToast(false);
                    }}
                    style={{ marginTop: '94px', marginRight: '175px' }}
                    severity='success'
                    title='Error!'
                    msg='Saved successfully!'
                  />
                </Grid>
                <Grid item md={12} lg={2}>
                  <span
                    style={{
                      float: 'right',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <BSaveCancel
                      saveEvent={e => {
                        e.preventDefault();
                        handleSubmit();
                        refetch();
                      }}
                      saveDisabled={!dirty}
                      cancelEvent={() => {
                        dirty ? setUnsaveConfirm({ open: true }) : gotoHome();
                      }}
                      saveId={generateID('btn', 'pr_device', 'save_top')}
                      cancelId={generateID('btn', 'pr_device', 'cancel_top')}
                    />
                  </span>
                </Grid>
              </Grid>
            ) : null}

            <Grid
              container
              spacing={2}
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              <h2>Device Status</h2>
              <hr style={{ color: 'rgba(0, 0, 0, 0.12)', width: '100%' }} />
              <Grid
                container
                spacing={2}
                style={{ marginBottom: '10px', marginTop: '10px' }}
              >
                <Grid item lg={3}>
                  <Card
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    variant='outlined'
                  >
                    <div
                      id={generateID('txt', 'pr_device', 'activation_state')}
                    >
                      <h3>Activation State</h3>
                      {activationStatusCards[activationStatusIndex]}
                    </div>
                    {activationStatusIndex === STATUS_DEVICE_PENDING && (
                      <BButton // Activate Button - displays a test team workaround button
                        id='activate'
                        clickEvent={() => {
                          handleActivateDevice(values);
                        }}
                        caption='Activate'
                        style={{ marginTop: 20 }}
                      />
                    )}
                    {activationStatusIndex >= STATUS_DEVICE_PENDING &&
                      activationStatusIndex <= STATUS_DEVICE_ACTIVE && (
                        <BButton // Deactivate Button
                          id={generateID('btn', 'pr_device', 'deactivate')}
                          clickEvent={() =>
                            setDeactivateDeviceConfirm({ open: true, data: '' })
                          }
                          caption='Deactivate'
                          style={{ marginTop: 20 }}
                        />
                      )}
                  </Card>
                </Grid>
                {activationStatusIndex >= STATUS_DEVICE_ACTIVE && (
                  <>
                    <RecordingStartCard
                      activationState={activationStatusIndex}
                      recordingStartedAt={values.recording_started_at}
                    />
                    <WearTimeCard
                      activationStatus={activationStatusIndex}
                      recording_started_at={values.recording_started_at}
                      prescribed_wear_time_days={
                        initialValues.prescribed_wear_time_days
                      }
                    />
                    <DataReceivedCard
                      activationState={activationStatusIndex}
                      lastTransmission={values.last_transmission_timestamp}
                      batteryLevelPercentage={values.battery_level_percentage}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              <h2>Device Information</h2>
              <hr style={{ color: 'rgba(0, 0, 0, 0.12)', width: '100%' }} />
              <Grid
                container
                spacing={2}
                style={{ marginBottom: '10px', marginTop: '10px' }}
              >
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={3}>
                      <BDropDown
                        id={generateID('drpdwn', 'pr_device', 'application')}
                        label='Application'
                        menuItems={DEVICE_APPLICATION_OPTIONS}
                        errors={errors.application}
                        shrink={true}
                        labelWidth={55}
                        fullWidth={true}
                        required={true}
                        disabled={
                          activationStatusIndex >= STATUS_DEVICE_DEACTIVATED ||
                          !canRegisterDevice
                        }
                        value={values.application}
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('application', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <BTextField
                        fullWidth={true}
                        value={values.implantId}
                        required={true}
                        shrink={true}
                        label='Device SN'
                        id={generateID('input', 'pr_device', 'device_id')}
                        name='implantId'
                        disabled={
                          activationStatusIndex >= STATUS_DEVICE_ACTIVE ||
                          !canRegisterDevice
                        }
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('implantId', e.target.value);
                        }}
                        handleBlur={handleBlur}
                        errors={errors.implantId ? errors.implantId : false}
                        touched={touched.implantId ? true : false}
                        startAdornment={undefined}
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <BDropDown
                        id={generateID('drpdwn', 'pr_device', 'mode')}
                        label='Mode'
                        menuItems={DEVICE_MODES}
                        shrink={true}
                        labelWidth={55}
                        fullWidth={true}
                        value={values.type}
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('type', e.target.value);
                        }}
                        disabled={
                          activationStatusIndex >= STATUS_DEVICE_DEACTIVATED ||
                          !canRegisterDevice
                        }
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <FormControl style={{ width: '100%' }}>
                        <FormLabel
                          id={generateID('slider', 'pr_device', 'wear-time')}
                        >
                          Prescribed Wear Time
                        </FormLabel>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Slider
                            aria-labelledby={generateID(
                              'slider',
                              'pr_device',
                              'wear-time'
                            )}
                            name='controlled-radio-buttons-group1'
                            step={1}
                            max={30}
                            min={2}
                            value={values.prescribed_wear_time_days}
                            onChange={(e, value) => {
                              handleChange(e);
                              setFieldValue('prescribed_wear_time_days', value);
                            }}
                            valueLabelDisplay='off'
                            disabled={
                              activationStatusIndex >=
                                STATUS_DEVICE_DEACTIVATED || !canRegisterDevice
                            }
                          />
                          <label style={{ padding: 10 }}>
                            {values.prescribed_wear_time_days}&nbsp;days
                          </label>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              <h2>Contact Information</h2>
              <hr style={{ color: 'rgba(0, 0, 0, 0.12)', width: '100%' }} />
              <Grid
                container
                spacing={2}
                style={{ marginBottom: '10px', marginTop: '10px' }}
              >
                <Grid item lg={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={3}>
                      <BTextField
                        id={generateID(
                          'input',
                          'pr_device',
                          'referring_provider_name'
                        )}
                        fullWidth={true}
                        shrink={true}
                        required={true}
                        label='Referring Provider'
                        touched={
                          touched.referring_provider
                            ? touched.referring_provider
                            : false
                        }
                        handleBlur={handleBlur}
                        value={values.referring_provider}
                        name='referring_provider'
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('referring_provider', e.target.value);
                        }}
                        errors={
                          errors.referring_provider
                            ? errors.referring_provider
                            : false
                        }
                        startAdornment={undefined}
                        disabled={
                          activationStatusIndex >= STATUS_DEVICE_DEACTIVATED ||
                          !canRegisterDevice
                        }
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <BTextField
                        fullWidth={true}
                        shrink={true}
                        id={generateID(
                          'input',
                          'pr_device',
                          'prescribing_provider_name'
                        )}
                        required={true}
                        label='Prescribing Provider'
                        touched={
                          touched.prescribing_provider
                            ? touched.prescribing_provider
                            : false
                        }
                        handleBlur={handleBlur}
                        value={values.prescribing_provider}
                        name='prescribing_provider'
                        handleChange={e => {
                          handleChange(e);
                          setFieldValue('prescribing_provider', e.target.value);
                        }}
                        errors={
                          errors.prescribing_provider
                            ? errors.prescribing_provider
                            : false
                        }
                        startAdornment={undefined}
                        disabled={
                          activationStatusIndex >= STATUS_DEVICE_DEACTIVATED ||
                          !canRegisterDevice
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: '10px', marginTop: '10px' }}
            >
              <hr style={{ color: 'rgba(0, 0, 0, 0.12)', width: '100%' }} />
              {canRegisterDevice ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <BToastMessage
                      id={generateID(
                        'toast',
                        'pr_device',
                        'notification_msg_btm'
                      )}
                      open={showSavedChangesToast}
                      close={() => {
                        setShowSavedChangesToast(false);
                      }}
                      style={{ position: 'inherit', marginRight: '5px' }}
                      severity='success'
                      title='Error!'
                      msg='Saved successfully!'
                    />
                    <span
                      style={{
                        float: 'right',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <BSaveCancel
                        saveId={generateID('btn', 'pr_device', 'save_btm')}
                        cancelId={generateID('btn', 'pr_device', 'cancel_btm')}
                        saveEvent={e => {
                          e.preventDefault();
                          handleSubmit();
                          refetch(); // get data again
                        }}
                        saveDisabled={!dirty}
                        cancelEvent={() => {
                          if (dirty) {
                            setUnsaveConfirm({ open: true });
                          } else {
                            gotoHome();
                          }
                        }}
                      />
                    </span>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
};

export default PatientRegistrationDevice;

const RecordingStartCard = ({ activationState, recordingStartedAt }) => (
  <Grid item lg={3}>
    <Card
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
      variant='outlined'
    >
      <div id={generateID('txt', 'pr_device', 'recording_start')}>
        <h3>Recording Start</h3>
        {getPrettyDate(new Date(recordingStartedAt), DD_MMM_YYYY, true)}&nbsp;
        {activationState === STATUS_DEVICE_DEACTIVATED ||
          `(${getDiffFromActivation(recordingStartedAt)})`}
      </div>
    </Card>
  </Grid>
);

const DataReceivedCard = ({
  activationState,
  lastTransmission,
  batteryLevelPercentage = 100,
}) => {
  const BATTERY_LEVEL_WARNING_THRESHOLD = 50;
  const DAYS_SINCE_TRANSMISSION_WARNING_THRESHOLD = 1;
  const exceedsThreshold = dataReceivedExceedsThreshold(
    lastTransmission,
    DAYS_SINCE_TRANSMISSION_WARNING_THRESHOLD
  );
  const syncIconColor = exceedsThreshold ? 'error' : 'action';
  return (
    <Grid item lg={3}>
      <Card
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
        }}
        variant='outlined'
      >
        <div id={generateID('txt', 'pr_device', 'data_last_received')}>
          <h3>Data Received</h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              {activationState === STATUS_DEVICE_DEACTIVATED ? (
                <span>{getPrettyDate(lastTransmission, DD_MMM_YYYY)}</span>
              ) : (
                <>
                  <SyncIcon
                    color={syncIconColor}
                    style={{ transform: 'rotate(45deg) scaleX(-1)' }}
                  />
                  <span>{getDiffFromActivation(lastTransmission)}</span>
                </>
              )}
            </div>
            {activationState !== STATUS_DEVICE_DEACTIVATED &&
              batteryLevelPercentage < BATTERY_LEVEL_WARNING_THRESHOLD && (
                <div>
                  <Battery1BarIcon color={'error'} />
                  <span>Switch MCT patch</span>
                </div>
              )}
          </div>
        </div>
      </Card>
    </Grid>
  );
};

const WearTimeCard = ({
  activationStatus,
  prescribed_wear_time_days,
  recording_started_at,
}) => {
  const remainingDays = calculateWearTimeRemaining(
    recording_started_at,
    prescribed_wear_time_days
  );

  const wearTimePercentage =
    ((prescribed_wear_time_days - remainingDays) / prescribed_wear_time_days) *
    100;
  let text = ''; // add descriptive text to the wear time based on other factors
  if (activationStatus <= STATUS_DEVICE_ACTIVE)
    if (wearTimePercentage < 100)
      text =
        remainingDays === 1
          ? `${remainingDays} day left`
          : `${remainingDays} days left`;
    else if (wearTimePercentage > 100)
      text =
        remainingDays === -1
          ? `${remainingDays} day over`
          : `${remainingDays} days over`;
    else text = `${prescribed_wear_time_days} days reached`;
  // wearTimePercentage === 100
  else {
    if (wearTimePercentage === 100)
      text = `${prescribed_wear_time_days} days reached`;
    else {
      text = `${prescribed_wear_time_days - remainingDays} `;
      text +=
        prescribed_wear_time_days - remainingDays === 1
          ? `day worn`
          : `days worn`;
      text +=
        remainingDays > 0
          ? ` (${remainingDays} under)`
          : ` (${-remainingDays} over)`;
    }
  }

  return (
    <Grid item lg={3}>
      <Card
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
        variant='outlined'
      >
        <div
          id={generateID('txt', 'pr_device', 'wear_time')}
          style={{ width: '90%' }}
        >
          <h3>Wear Time</h3>
          <BProgress
            activationStatus={activationStatus}
            progress={wearTimePercentage}
          />
          <span>{text}</span>
        </div>
      </Card>
    </Grid>
  );
};

const ActiveCard = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <CheckCircleIcon color={'success'} />
    <Typography sx={{ color: 'success.main', marginLeft: 1 }}>
      Active
    </Typography>
  </div>
);

const PendingCard = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <ScheduleIcon color={'info'} />
    <p style={{ color: '#0083AD', marginLeft: 10 }}>Pending</p>
  </div>
);

const EndedCard = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <CancelIcon />
    Ended
  </div>
);

const IncompleteCard = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <IndeterminateCheckBoxIcon />
    <Typography sx={{ color: 'failure.main', marginLeft: 1 }}>
      Incomplete
    </Typography>
  </div>
);

const activationStatusCards = {
  [STATUS_DEVICE_INCOMPLETE]: <IncompleteCard />,
  [STATUS_DEVICE_ACTIVE]: <ActiveCard />,
  [STATUS_DEVICE_PENDING]: <PendingCard />,
  [STATUS_DEVICE_DEACTIVATED]: <EndedCard />,
};
