import { ROUTE_COMPONENTS } from './permissions_components';
import {
  D_HISTORY_ALL_RECORDS,
  D_HISTORY_CEMSUMMARY_REPORT,
  D_HISTORY_DEVICE,
  D_HISTORY_EVENTS,
  D_HISTORY_EVENT_REPORT,
  D_HISTORY_NOTE,
  D_HISTORY_PROFILE,
  D_HISTORY_SETTINGS,
  D_HISTORY_SUMMARY_REPORT,
  HISTORY_ALL_RECORDS,
  HISTORY_CEMSUMMARY_REPORT,
  HISTORY_DEVICE,
  HISTORY_EVENTS,
  HISTORY_EVENT_REPORT,
  HISTORY_NOTE,
  HISTORY_PROFILE,
  HISTORY_SETTINGS,
  HISTORY_SUMMARY_REPORT,
  IUserAccessRole,
  IUserAccessPermissions,
  IUserAccessObject,
} from './types';

const SUPERSET_NAVIGATION_MENU = {
  EVENT_QUEUE: {
    id: 'event_queue',
    linkTo: '/events',
    label: 'Event Queue',
    type: 'EVENT',
    value: '/events',
  },
  PATIENT_LIST: {
    id: 'patient_list',
    linkTo: '/patients',
    label: 'Patient List',
    type: 'PATIENT',
    value: '/patients',
  },
  NEW_PATIENT: {
    id: 'new_patient',
    linkTo: '/patient/new',
    label: 'NEW PATIENT',
    type: 'patientregistration',
    value: '/patient/new',
  },
  CURRENT_PATIENT: {
    id: 'current_patient',
    linkTo: null,
    label: 'CURRENT PATIENT',
    type: 'patientregistration',
    value: '/patient/:id',
  },
  ADMIN: {
    id: 'admin',
    linkTo: '/admin',
    label: 'ADMIN',
    type: null,
    value: '/admin',
  },
};

export const SUPERSET_HISTORY_FILTER_LIST = {
  [HISTORY_ALL_RECORDS]: {
    value: HISTORY_ALL_RECORDS,
    label: D_HISTORY_ALL_RECORDS,
  },
  [HISTORY_EVENTS]: { value: HISTORY_EVENTS, label: D_HISTORY_EVENTS },
  [HISTORY_NOTE]: { value: HISTORY_NOTE, label: D_HISTORY_NOTE },
  [HISTORY_EVENT_REPORT]: {
    value: HISTORY_EVENT_REPORT,
    label: D_HISTORY_EVENT_REPORT,
  },
  [HISTORY_SUMMARY_REPORT]: {
    value: HISTORY_SUMMARY_REPORT,
    label: D_HISTORY_SUMMARY_REPORT,
  },
  [HISTORY_CEMSUMMARY_REPORT]: {
    value: HISTORY_CEMSUMMARY_REPORT,
    label: D_HISTORY_CEMSUMMARY_REPORT,
  },
  [HISTORY_SETTINGS]: { value: HISTORY_SETTINGS, label: D_HISTORY_SETTINGS },
  [HISTORY_PROFILE]: { value: HISTORY_PROFILE, label: D_HISTORY_PROFILE },
  [HISTORY_DEVICE]: { value: HISTORY_DEVICE, label: D_HISTORY_DEVICE },
};

export const USER_ACCESS_BY_ROLE: { [k in IUserAccessRole] } = {
  mctECGTech: {
    // technician notes
    canAccessTechNotes: true,
    // profile
    canUpdatePatient: true,
    // settings
    canUpdateSettings: true,
    // canRegisterDevice
    canRegisterDevice: true,
    canLaunchECGViewer: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.EVENT_QUEUE,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENTS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_NOTE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.events,
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
    ],
  },
  clinician: {
    canAccessTechNotes: false, // technician notes
    canUpdatePatient: true, // profile
    canUpdateSettings: true, // settings
    canRegisterDevice: true, // canRegisterDevice
    canLaunchECGViewer: false,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
    ],
  },
  system_admin: {
    canAccessTechNotes: true, // technician notes
    canUpdatePatient: true, // profile
    canUpdateSettings: true, // settings
    canRegisterDevice: true, // canRegisterDevice
    canLaunchECGViewer: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.EVENT_QUEUE,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
      SUPERSET_NAVIGATION_MENU.ADMIN,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_NOTE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENTS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.events,
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
      ROUTE_COMPONENTS.admin,
    ],
  },
  org_admin: {
    canAccessTechNotes: true, // technician notes
    canUpdatePatient: true, // profile
    canUpdateSettings: true, // settings
    canRegisterDevice: true, // canRegisterDevice
    canLaunchECGViewer: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.EVENT_QUEUE,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
      SUPERSET_NAVIGATION_MENU.ADMIN,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.events,
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
      ROUTE_COMPONENTS.admin,
    ],
  },
  distributor_admin: {
    canAccessTechNotes: true, // technician notes
    canUpdatePatient: true, // profile
    canUpdateSettings: true, // settings
    canRegisterDevice: true, // canRegisterDevice
    canLaunchECGViewer: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.EVENT_QUEUE,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
      SUPERSET_NAVIGATION_MENU.ADMIN,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.events,
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
      ROUTE_COMPONENTS.admin,
    ],
  },
  qc_user: {
    canAccessTechNotes: false, // technician notes
    canUpdatePatient: false, // profile
    canUpdateSettings: false, // settings
    canRegisterDevice: false, // canRegisterDevice
    canLaunchECGViewer: false,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [ROUTE_COMPONENTS.events, ROUTE_COMPONENTS.patient],
  },
};

export const USER_ACCESS_BY_PERMISSION: { [k in IUserAccessPermissions] } = {
  MCTTechnicianAccess: {
    canAccessTechNotes: true,
    canUpdatePatient: true,
    canUpdateSettings: true,
    canRegisterDevice: true,
    canLaunchECGViewer: true,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.EVENT_QUEUE,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENTS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_NOTE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.events,
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
    ],
  },
  ClinicianAccess: {
    canAccessTechNotes: false,
    canUpdatePatient: true,
    canUpdateSettings: true,
    canRegisterDevice: true,
    canLaunchECGViewer: false,
    navigationMenu: [
      SUPERSET_NAVIGATION_MENU.PATIENT_LIST,
      SUPERSET_NAVIGATION_MENU.NEW_PATIENT,
      SUPERSET_NAVIGATION_MENU.CURRENT_PATIENT,
    ],
    historyFilter: [
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_ALL_RECORDS],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_EVENT_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_CEMSUMMARY_REPORT],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_DEVICE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_PROFILE],
      SUPERSET_HISTORY_FILTER_LIST[HISTORY_SETTINGS],
    ],
    routeList: [
      ROUTE_COMPONENTS.patients,
      ROUTE_COMPONENTS.patient,
      ROUTE_COMPONENTS.reports,
    ],
  },
};

const getColsFromList = (colnames, collist) => {
  return colnames.map(name => collist[name]);
};
const EL_ROLE_SYS_ADMIN = [
  'current_status',
  'tevents',
  'tevent_date',
  'tviewer',
  'name',
  'date_of_birth',
  'tmct_ID',
  'patient_identifier',
  'history',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'age_sex',
  'indications',
  'organization',
];

const EL_ROLE_ORG_ADMIN = [
  'current_status',
  'tevents',
  'tevent_date',
  'tviewer',
  'name',
  'date_of_birth',
  'tmct_ID',
  'patient_identifier',
  'history',
  'prescribing_provider',
  'tdevice_status',
  'age_sex',
  'indications',
  'organization',
];

const EL_ROLE_DIS_ADMIN = [
  'current_status',
  'tevents',
  'tevent_date',
  'tviewer',
  'name',
  'date_of_birth',
  'tmct_ID',
  'patient_identifier',
  'history',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'age_sex',
  'indications',
  'organization',
];

const EL_ROLE_CLINICIAN = [
  'current_status',
  'tevents',
  'tevent_date',
  'name',
  'date_of_birth',
  'tmct_ID',
  'patient_identifier',
  'history',
  'prescribing_provider',
  'tdevice_status',
  'age_sex',
  'indications',
  'organization',
];

const EL_ROLE_TECH = [
  'current_status',
  'tviewer',
  'name',
  'date_of_birth',
  'tmct_ID',
  'patient_identifier',
  'history',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'age_sex',
  'indications',
  'organization',
];

const PL_ROLE_SYS_ADMIN = [
  'name',
  'date_of_birth',
  'patient_identifier',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'history',
  'tviewer_due',
  'age_sex',
  'indications',
  'tmct_ID',
  'organization',
  'application',
];

const PL_ROLE_ORG_ADMIN = [
  'name',
  'date_of_birth',
  'patient_identifier',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'history',
  'age_sex',
  'indications',
  'tmct_ID',
  'organization',
  'application',
];

const PL_ROLE_DIS_ADMIN = [
  'name',
  'date_of_birth',
  'patient_identifier',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'history',
  'age_sex',
  'indications',
  'tmct_ID',
  'organization',
  'application',
];

const PL_ROLE_CLINICIAN = [
  'name',
  'date_of_birth',
  'patient_identifier',
  'prescribing_provider',
  'tdevice_status',
  'history',
  'age_sex',
  'indications',
  'tmct_ID',
  'application',
];

const PL_ROLE_TECH = [
  'name',
  'date_of_birth',
  'patient_identifier',
  'prescribing_provider',
  'technician_notes',
  'tdevice_status',
  'history',
  'tviewer_due',
  'age_sex',
  'indications',
  'tmct_ID',
  'organization',
  'application',
];

const PATIENT_LIST_COLUMNS = {
  name: {
    hide: false,
    align: 'left',
    field: 'name',
    label: 'Patient Name',
    order: 1,
    width: 295,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'name',
  },
  date_of_birth: {
    hide: false,
    align: 'left',
    field: 'date_of_birth',
    label: 'Date of Birth',
    order: 2,
    width: 150,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'date_of_birth',
  },
  patient_identifier: {
    hide: false,
    align: 'left',
    field: 'patient_identifier',
    label: 'Patient Identifier',
    order: 4,
    width: 155,
    hideable: false,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'patient_identifier',
  },
  prescribing_provider: {
    hide: false,
    align: 'left',
    field: 'prescribing_provider',
    label: 'Prescribing Provider',
    order: 5,
    width: 198,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'prescribing_provider',
  },
  technician_notes: {
    hide: false,
    align: 'left',
    field: 'technician_notes',
    label: 'Technician Notes',
    order: 0,
    width: 278,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: true,
    resizeable: true,
    header_name: 'technician_notes',
  },
  tdevice_status: {
    hide: true,
    align: 'left',
    field: 'tdevice_status',
    label: 'Device Status',
    order: 6,
    width: 157,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'tdevice_status',
  },
  history: {
    hide: true,
    align: 'left',
    field: 'history',
    label: 'Reports',
    order: 7,
    width: 287,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'history',
  },
  tviewer_due: {
    hide: false,
    align: 'left',
    field: 'tviewer_due',
    label: 'Viewer / Due',
    order: 3,
    width: 203,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'tviewer_due',
  },
  age_sex: {
    hide: false,
    align: 'left',
    field: 'age_sex',
    label: 'Age/Sex',
    order: 8,
    width: 205,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: false,
    resizeable: true,
    header_name: 'age_sex',
  },
  indications: {
    hide: false,
    align: 'left',
    field: 'indications',
    label: 'Indications',
    order: 9,
    width: 235,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: true,
    resizeable: true,
    header_name: 'indications',
  },
  tmct_ID: {
    hide: false,
    align: 'left',
    field: 'tmct_ID',
    label: 'Device ID',
    order: 10,
    width: 264,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'tmct_ID',
  },
  organization: {
    hide: false,
    align: 'left',
    field: 'organization',
    label: 'Organization',
    order: 11,
    width: 200,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: false,
    filterable: true,
    resizeable: true,
    header_name: 'organization',
  },
  application: {
    hide: false,
    align: 'left',
    field: 'application',
    label: 'Application',
    order: 1,
    width: 295,
    hideable: true,
    maxWidth: 500,
    minWidth: 50,
    sortable: true,
    filterable: true,
    resizeable: true,
    header_name: 'application',
  },
};

const EVENT_QUEUE_COLUMNS = {
  current_status: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'current_status',
    width: 200,
    minWidth: 150,
    maxWidth: null,
    order: 0,
    label: 'Status',
    header_name: 'current_status',
  },
  tevents: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'tevents',
    width: 390,
    minWidth: 50,
    maxWidth: null,
    order: 1,
    label: 'Clinical Events',
    header_name: 'tevents',
  },
  tevent_date: {
    sortable: true,
    filterable: false,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tevent_date',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 2,
    label: 'Event Date',
    header_name: 'tevent_date',
  },
  tviewer: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tviewer',
    width: 100,
    minWidth: 50,
    maxWidth: null,
    order: 3,
    label: 'Viewer',
    header_name: 'tviewer',
  },
  name: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'name',
    width: 251,
    minWidth: 50,
    maxWidth: null,
    order: 8,
    label: 'Patient Name',
    header_name: 'name',
  },
  date_of_birth: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'date_of_birth',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 12,
    label: 'Date of Birth',
    header_name: 'date_of_birth',
  },
  tmct_ID: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tmct_ID',
    width: 150,
    minWidth: 50,
    maxWidth: null,
    order: 4,
    label: 'Device ID',
    header_name: 'tmct_ID',
  },
  patient_identifier: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'patient_identifier',
    width: 241,
    minWidth: 50,
    maxWidth: null,
    order: 5,
    label: 'Patient Identifier',
    header_name: 'patient_identifier',
  },
  history: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'history',
    width: 112,
    minWidth: 50,
    maxWidth: null,
    order: 9,
    label: 'Reports',
    header_name: 'history',
  },
  prescribing_provider: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'prescribing_provider',
    width: 154,
    minWidth: 50,
    maxWidth: null,
    order: 11,
    label: 'Prescribing Provider',
    header_name: 'prescribing_provider',
  },
  technician_notes: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'technician_notes',
    width: 241,
    minWidth: 50,
    maxWidth: null,
    order: 13,
    label: 'Technician Notes',
    header_name: 'technician_notes',
  },
  tdevice_status: {
    sortable: true,
    filterable: true,
    hideable: true,
    resizeable: true,
    hide: false,
    align: 'left',
    field: 'tdevice_status',
    width: 100,
    minWidth: 50,
    maxWidth: null,
    order: 10,
    label: 'Device Status',
    header_name: 'tdevice_status',
  },
  age_sex: {
    sortable: false,
    filterable: false,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'age_sex',
    width: 75,
    minWidth: 50,
    maxWidth: null,
    order: 6,
    label: 'Age / Sex',
    header_name: 'age_sex',
  },
  indications: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'indications',
    width: 162,
    minWidth: 50,
    maxWidth: null,
    order: 7,
    label: 'Indications',
    header_name: 'indications',
  },
  organization: {
    sortable: false,
    filterable: true,
    hideable: false,
    resizeable: false,
    hide: false,
    align: 'left',
    field: 'organization',
    width: 200,
    minWidth: 50,
    maxWidth: null,
    order: 14,
    label: 'Organization',
    header_name: 'organization',
  },
};

export const DEFAULT_PATIENT_LIST_VIEW_PER_USER_ROLE: {
  [k: string]: { columns; density; pageSize };
} = {
  system_admin: {
    columns: getColsFromList(PL_ROLE_SYS_ADMIN, PATIENT_LIST_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  org_admin: {
    columns: getColsFromList(PL_ROLE_ORG_ADMIN, PATIENT_LIST_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  distributor_admin: {
    columns: getColsFromList(PL_ROLE_DIS_ADMIN, PATIENT_LIST_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  clinician: {
    columns: getColsFromList(PL_ROLE_CLINICIAN, PATIENT_LIST_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },

  mctECGTech: {
    columns: getColsFromList(PL_ROLE_TECH, PATIENT_LIST_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
};

export const DEFAULT_EVENT_QUEUE_VIEW_PER_USER_ROLE: {
  [k: string]: { columns; density; pageSize };
} = {
  system_admin: {
    columns: getColsFromList(EL_ROLE_SYS_ADMIN, EVENT_QUEUE_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  org_admin: {
    columns: getColsFromList(EL_ROLE_ORG_ADMIN, EVENT_QUEUE_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  distributor_admin: {
    columns: getColsFromList(EL_ROLE_DIS_ADMIN, EVENT_QUEUE_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  clinician: {
    columns: getColsFromList(EL_ROLE_CLINICIAN, EVENT_QUEUE_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
  mctECGTech: {
    columns: getColsFromList(EL_ROLE_TECH, EVENT_QUEUE_COLUMNS),
    density: 'standard',
    pageSize: 20,
  },
};

export const getFinalPermissions = (
  role: string,
  permissions: string[]
): IUserAccessObject => {
  const booleanFields = [
    'canAccessTechNotes',
    'canUpdatePatient',
    'canUpdateSettings',
    'canRegisterDevice',
    'canLaunchECGViewer',
  ];

  const finalPermissions = {
    canAccessTechNotes: false,
    canUpdatePatient: false,
    canUpdateSettings: false,
    canRegisterDevice: false,
    canLaunchECGViewer: false,
    navigationMenu: [],
    historyFilter: [],
    routeList: [],
  };

  const permission_objects = permissions.map(p => USER_ACCESS_BY_PERMISSION[p]);
  const role_object = role ? USER_ACCESS_BY_ROLE[role] : null;

  const all_access_objs = [...permission_objects, role_object];

  all_access_objs.forEach(access_obj => {
    if (!access_obj) return; // role may be null

    // Keep any existing functionality,
    // or enable it if the next role/permission grants it
    booleanFields.forEach(field => {
      finalPermissions[field] = finalPermissions[field] || access_obj[field];
    });

    // Navigation Menu - create union set of all menu items, then convert to an array
    const navigation_curr = access_obj.navigationMenu.map(
      menu_item => menu_item.id
    );
    const navigation_all = finalPermissions.navigationMenu.map(
      menu_item => menu_item.id
    );
    const navigation_union_set = new Set([
      ...navigation_curr,
      ...navigation_all,
    ]);
    const navigation_array = Array.from(navigation_union_set).map(
      k => SUPERSET_NAVIGATION_MENU[k.toUpperCase()]
    );

    finalPermissions.navigationMenu = navigation_array;

    //  History Filter - create union set of all history filters, then convert to an array
    const history_curr = access_obj.historyFilter.map(
      menu_item => menu_item.value
    );
    const history_all = finalPermissions.historyFilter.map(
      menu_item => menu_item.value
    );
    const history_union_set = new Set([...history_curr, ...history_all]);
    const history_array = Array.from(history_union_set).map(
      k => SUPERSET_HISTORY_FILTER_LIST[k]
    );

    finalPermissions.historyFilter = history_array;

    //  Route List - create union set of all route components, then convert to an array
    const route_curr = access_obj.routeList.map(route => route.id);
    const route_all = finalPermissions.routeList.map(route => route.id);
    const route_union_set = new Set([...route_curr, ...route_all]);
    const route_array = Array.from(route_union_set).map(
      k => ROUTE_COMPONENTS[k]
    );

    finalPermissions.routeList = route_array;
  });

  finalPermissions.routeList = finalPermissions.routeList.map(r => r.component);
  return finalPermissions as IUserAccessObject;
};
