import { useContext, useState } from 'react';
import { Typography, Popover, Badge } from '@mui/material';

import BIcon from '../../../components/common/BIcon';
import BasePatientNotification from '../../../components/patientNotifications/BasePatientNotification';
import {
  PatientArrhythmiaEventIconList,
  DeviceEventIconList,
} from '../../../components/patientNotifications/PatientlistDeviceEvent';

import {
  DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
  DD_MMM_C_YYYY,
  HISTORY_EVENT_REPORT,
} from '../../../types/types';
import {
  generateID,
  getEventIconStyles,
  getPrettyDate,
} from '../../utilityFunctions';
import { ICONS, Device_Event_Group_Status } from '../../IconSelection';
import { formECGViewerURL } from './logicPatientList';
import { PatientListCellContentContext } from '../../../EventMonitorPatientList';
import Config, { IConfig } from './../../../types/Config';
import {
  GET_REPORT_LINK,
  SET_REPORT_VIEWED,
} from '../../../data/queries/report';
import { useMutation } from '@apollo/client';

import { capitalize, filter, groupBy } from 'lodash';

import { Link, useNavigate } from 'react-router-dom';
import { AccessPermissionsContext } from '../../../auth/useAccessPermissions';
import { MAP_PATIENT_EVENT_LIST_COL_FILTERS } from '../../../types/constants';

const getReadableReportName = reportName => {
  return capitalize(reportName.replaceAll('_', ' ')) + ': ';
};

/************************** PATIENT NAME **************************/
const PLPatientName = ({ params }) => {
  const patDetails = useContext(PatientListCellContentContext);
  return (
    <Typography
      noWrap
      component='span'
      style={{ cursor: 'pointer' }}
      sx={theme => ({
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
      })}
      variant='body1'
      onClick={() => {
        patDetails.gotoPatientRegistration(
          'Profile',
          params.row.patientId,
          params.row.deviceSettingId,
          params.row.registered
        );
      }}
    >
      {params.row.lastName + ', ' + params.row.firstName}
    </Typography>
  );
};

/************************** PATIENT ID/MRN **************************/
const PLPatientIdMRN = ({ params }) => {
  return (
    <Typography noWrap component='span' variant='body1'>
      {params.row.patientIdentifier}
    </Typography>
  );
};

/************************** EVENT DATE **************************/
const PLEventDate = ({ params }) => {
  const patDetails = useContext(PatientListCellContentContext);
  return (
    <Typography
      component='span'
      style={{ display: 'inline-flex', alignContent: 'center' }}
    >
      {params.row.endTime
        ? getPrettyDate(
            params.row.endTime,
            DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
            true
          )
        : '-'}
    </Typography>
  );
};

/************************** CLINICAL EVENTS **************************/
const PLEvents = ({ params }) => {
  {
    const patContext = useContext(PatientListCellContentContext);
    const patDetails = params.row;
    return patDetails.registered ? (
      <>
        {
          // No events. no ECG viewer, no time range
          patDetails.endTime ? (
            <>
              &nbsp;&nbsp;&nbsp;
              <div
                id={generateID(
                  'block',
                  'pl',
                  `event_${patDetails.deviceEventId}`
                )}
                onClick={e => {
                  patContext.openDeviceEvent(e, patDetails.deviceEventId);
                }}
                style={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <PatientArrhythmiaEventIconList
                  eventList={[...patDetails.patientEventsList]}
                />
                <DeviceEventIconList
                  eventList={[...patDetails.deviceEventsList]}
                />
              </div>
              <Popover
                open={patDetails.deviceEventId == patContext.openPopOverId}
                anchorEl={patContext.anchorEl}
                onClose={patContext.handleClose}
                style={{ border: 'solid grey' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <BasePatientNotification
                  id={generateID('win', 'esumwin', patDetails.deviceEventId)}
                  deviceEventId={patDetails.deviceEventId}
                  firstName={patDetails.firstName}
                  deviceSettingId={patDetails.deviceSettingId}
                  lastName={patDetails.lastName}
                  patientId={patDetails.patientId}
                  registered={patDetails.registered}
                  age={patDetails.age}
                  sex={patDetails.sex}
                  indications={patDetails.indications}
                  endTime={patDetails.endTime}
                  deviceId={patDetails.deviceId}
                  current_status={patDetails.current_status}
                  eventList={[
                    ...patDetails.patientEventsList,
                    ...patDetails.deviceEventsList,
                  ]}
                  icons={ICONS}
                  clickHandler={patContext.handleClose}
                  chartData={patContext.chartData}
                  technicianNotes={patDetails.technicianNotes}
                  gotoPatientRegistration={patContext.gotoPatientRegistration}
                  eventThresholdObj={patDetails.eventThresholdObj}
                  orgContactDetails={patDetails.orgContactDetails}
                />
              </Popover>
            </>
          ) : null
        }
      </>
    ) : (
      <p> Device not registered yet </p>
    );
  }
};

/************************** ACTIVATION STATUS ***********************/
const PLActivationStatus = ({ params }) => {
  const patDetails = params.row;
  let {
    activationStatus,
    switchPatchFlag,
    dataTransmissionFlag,
    activationStatusTooltip,
    switchPatchToolTip,
    dataTransmissionToolTip,
  } = patDetails?.deviceStatus;
  const display_text = activationStatus;
  if (display_text === null) return <>null</>;
  if (display_text === undefined) return <>undefined</>;

  return (
    <>
      <Typography title={activationStatusTooltip}>{display_text}</Typography>
      {switchPatchFlag ? (
        <>
          <span
            className='space-right device-status-battery'
            id={generateID(
              'icon',
              'plviewer',
              `device-status-patch_${patDetails.deviceId}_${patDetails.endTime}`
            )}
          ></span>
          <BIcon
            title={switchPatchToolTip}
            icon={ICONS['BATTERY']}
            style={getEventIconStyles('on')}
          ></BIcon>
        </>
      ) : null}
      {dataTransmissionFlag ? (
        <>
          <span
            className='space-right device-status-data-transmission'
            id={generateID(
              'icon',
              'plviewer',
              `device-status-dt_${patDetails.deviceId}_${patDetails.endTime}`
            )}
          ></span>
          <BIcon
            title={dataTransmissionToolTip}
            icon={ICONS['DATA_TRANSMISSION']}
            style={getEventIconStyles('on')}
          ></BIcon>
        </>
      ) : null}
    </>
  );
};

/************************** ECG Viewer **************************/
const PLViewer = ({ params }) => {
  const config = useContext<IConfig>(Config);
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const TOKEN = useContext(AccessPermissionsContext)?.token;
  const { canLaunchECGViewer } = USER_ACCESS;

  const patDetails = params.row;

  if (!canLaunchECGViewer) return null;
  return (
    <>
      {canLaunchECGViewer ? (
        <span
          id={generateID(
            'icon',
            'plviewer',
            `${patDetails.deviceId}_${patDetails.endTime}`
          )}
          style={{
            cursor: 'pointer',
          }}
          onClick={e => {
            if (typeof window !== 'undefined') {
              window.open(
                formECGViewerURL(
                  config.ecgApiEndpointUrl,
                  patDetails.deviceId + '/' + TOKEN
                )
              );
            }
          }}
        >
          <BIcon
            icon={ICONS['ECG_Viewer']}
            fontSize='large'
            class='patient-list-icons'
          />
        </span>
      ) : null}

      {patDetails?.deviceLocked ? (
        <span event-type={'lock'}>
          <BIcon
            icon={ICONS['LOCK']}
            fontSize='large'
            class='patient-list-icons'
          />
        </span>
      ) : null}
    </>
  );
};

/************************** AGE/SEX **************************/
const PLAgeSex = ({ params }) => {
  return (
    <Typography component='span' variant='body1'>
      {params.row.age + ':' + params.row.sex[0].toUpperCase()}
    </Typography>
  );
};

/************************** INDICATIONS **************************/
const PLIndications = ({ params }) => {
  return (
    <Typography component='span' noWrap title={params.row.indications}>
      {params.row.indications}
    </Typography>
  );
};

/************************** Device SN **************************/
const PLDeviceId = ({ params }) => {
  const patDetails = useContext(PatientListCellContentContext);
  return params?.row?.deviceId ? (
    <Typography component='span' variant='body1'>
      {params.row.deviceId}
    </Typography>
  ) : (
    <Typography
      noWrap
      component='span'
      style={{ cursor: 'pointer' }}
      sx={theme => ({
        color: theme.palette.primary.main,
      })}
      variant='body1'
      onClick={() => {
        patDetails.gotoPatientRegistration(
          'Device',
          params.row.patientId,
          params.row.deviceSettingId,
          params.row.registered
        );
      }}
    >
      Register Device
    </Typography>
  );
};

/************************** Technician Notes **************************/
const PLTechnicianNotes = ({ params }) => {
  return (
    <Typography
      noWrap
      title={params?.row?.technicianNotes}
      component='span'
      variant='body1'
    >
      {params?.row?.technicianNotes}
    </Typography>
  );
};

const EventReportWindow = ({ patDetails, params, eventListReports }) => {
  // assumes eventListReports is ordered in descending order by createdDate key
  let formReportList = [];
  const [getReportLink] = useMutation(GET_REPORT_LINK);
  const [setReportViewed] = useMutation(SET_REPORT_VIEWED);
  const { patientId, deviceSettingId, registered } = params.row;
  const permission = useContext(AccessPermissionsContext)?.permissions;

  if (eventListReports) {
    const MAX_EVENT_REPORT_IN_SUMMARY_WINDOW = 5;
    let grouped = {};
    let totalReports = Math.min(
      MAX_EVENT_REPORT_IN_SUMMARY_WINDOW,
      eventListReports.length
    );

    // form event report array to display in event summary window
    for (let report = 0; report < totalReports; report++) {
      let formReportItem = {};
      let eventReportDetails = eventListReports[report];
      let createdDate = getPrettyDate(
        eventReportDetails.createdAt,
        DD_MMM_C_YYYY
      );
      formReportItem['id'] = eventReportDetails.id;
      formReportItem['date'] = createdDate;
      formReportItem['viewed'] = eventReportDetails.viewed;
      formReportItem['fileLink'] = eventReportDetails.fileLink;
      formReportList.push(formReportItem);
    }

    // Add the order to similar dates
    let groupedCounter = {};
    grouped = groupBy(formReportList, report => report.date);

    for (let report = 0; report < totalReports; report++) {
      let date = formReportList[report].date;
      let totalCount = grouped[date].length;
      if (totalCount > 1) {
        if (date in groupedCounter) {
          formReportList[report]['order'] = groupedCounter[date];
          groupedCounter[date]--;
        } else {
          formReportList[report]['order'] = totalCount;
          groupedCounter[date] = totalCount - 1;
        }
      }
    }
  }

  let reports = formReportList;
  return (
    <div className='event-report-window'>
      <div className='title'>Event reports</div>
      {reports &&
        reports.map(item => {
          return (
            <div
              className='event-report'
              style={{ cursor: 'pointer' }}
              onClick={e => {
                getReportLink({
                  variables: { reportFilename: item?.fileLink },
                }).then((data: any) => {
                  let reportURL = data?.data?.getReportLink;
                  if (permission.includes('ClinicianAccess')) {
                    setReportViewed({ variables: { reportId: item.id } }).then(
                      reportViewData => {}
                    );
                  }
                  window.open(reportURL, '_blank');
                });
              }}
            >
              <>
                <BIcon
                  icon={ICONS['event_published']}
                  style={{
                    color: item.viewed ? '#F5907F' : '#C8071A',
                    fontWeight: item.viewed ? 'normal' : 'bold',
                    verticalAlign: 'middle',
                  }}
                  fontSize='large'
                  class='patient-list-icons'
                />
                <span
                  style={{
                    fontWeight: item.viewed ? 'normal' : 'bold',
                    verticalAlign: 'middle',
                  }}
                >
                  {item.date} {item?.order ? `(${item.order})` : null}{' '}
                </span>
              </>
            </div>
          );
        })}

      <Typography
        noWrap
        id={generateID(
          'link',
          'event-report-view-all',
          `${params.row.deviceSettingId}_${params.row.endTime}`
        )}
        component='div'
        style={{
          cursor: 'pointer',
          padding: '10px',
          fontSize: '12px',
          textDecoration: 'underline',
        }}
        sx={theme => ({
          color: theme.palette.primary.main,
        })}
        variant='body1'
        onClick={e => {
          patDetails.selectPatientId(patientId, deviceSettingId, registered);
          patDetails.gotoPatientRegistration(
            'History',
            patientId,
            deviceSettingId,
            true,
            { filter: HISTORY_EVENT_REPORT }
          );
        }}
      >
        View all in History
      </Typography>
    </div>
  );
};

/************************** HISTORY **************************/
// Show all the event reports and latest summary report
const PLHistory = ({ params }) => {
  const patDetails = useContext(PatientListCellContentContext);
  const eventListReports = params.row.eventListReports;
  const totalNotViewedReports = filter(eventListReports, {
    viewed: false,
  }).length;
  const summaryListReports = params.row.summaryListReports;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const reportWindowId =
    params.row.eventType === 'EVENT'
      ? `${params.row.deviceSettingId}_${params.row.endTime}`
      : params.row.patientId;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(previousOpen => !previousOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <span
        onClick={e => {
          patDetails.selectPatientId(
            params.row.patientId,
            params.row.deviceSettingId,
            params.row.registered
          );
          patDetails.gotoPatientRegistration(
            'History',
            params.row.patientId,
            params.row.deviceSettingId
          );
        }}
      >
        <BIcon
          icon={ICONS['REPORTS']}
          fontSize='large'
          class='patient-list-icons'
          style={{ verticalAlign: 'middle' }}
        />
      </span>
      {/* Provision for Event reports */}
      <span>
        {eventListReports.length > 0 ? (
          <span
            aria-describedby={params.row.patientId}
            onClick={handleClick}
            event-type={'event_report'}
            title={'Event Report'}
          >
            {totalNotViewedReports > 0 ? (
              <>
                <Badge
                  badgeContent={totalNotViewedReports}
                  color='error'
                  style={{ color: '#C8071A' }}
                >
                  <BIcon
                    icon={ICONS['event_published']}
                    style={{ color: '#C8071A', verticalAlign: 'middle' }}
                    fontSize='large'
                    class='patient-list-icons'
                  />
                </Badge>
              </>
            ) : (
              <span>
                <BIcon
                  icon={ICONS['event_published']}
                  style={{ color: '#F5907F', verticalAlign: 'middle' }}
                  fontSize='large'
                  class='patient-list-icons'
                />
              </span>
            )}
          </span>
        ) : null}
      </span>

      <Popover
        id={generateID('eventreportsumwin', 'plviewer', reportWindowId)}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ border: 'solid grey' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <EventReportWindow
          patDetails={patDetails}
          params={params}
          eventListReports={eventListReports}
        ></EventReportWindow>
      </Popover>

      {/* Provision for summary reports */}
      {summaryListReports &&
        summaryListReports.map((reportItem, key) => {
          const summaryReportLink = `/reports/${reportItem.id}`;

          return (
            <span
              key={key}
              style={{
                marginLeft: '10px',
                verticalAlign: 'middle',
              }}
            >
              <Link
                to={summaryReportLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <span
                  event-type={reportItem.type + '_' + reportItem.currentState}
                >
                  <BIcon
                    title={
                      getReadableReportName(
                        `${reportItem.type} ${reportItem.currentState}`
                      ) +
                      getPrettyDate(
                        reportItem.createdAt,
                        DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
                        true
                      )
                    }
                    icon={
                      ICONS[reportItem.type + '_' + reportItem.currentState]
                    }
                    fontSize='large'
                    class='patient-list-icons'
                    style={{ verticalAlign: 'middle' }}
                  />
                </span>
              </Link>
            </span>
          );
        })}
    </div>
  );
};

/************************** Report Due **************************/
const PLReportDue = ({ params }) => {
  const USER_ACCESS = useContext(AccessPermissionsContext)?.access;
  const TOKEN = useContext(AccessPermissionsContext)?.token;
  const { canLaunchECGViewer } = USER_ACCESS;

  const config = useContext<IConfig>(Config);
  const patDetails = params.row;
  const reports = params.row.reportStateList;
  return (
    <>
      {canLaunchECGViewer ? (
        <span
          id={generateID(
            'icon',
            'plviewer',
            `${patDetails.deviceId}_${patDetails.endTime}`
          )}
          style={{ cursor: 'pointer' }}
          onClick={e => {
            if (typeof window !== 'undefined') {
              window.open(
                formECGViewerURL(
                  config.ecgApiEndpointUrl,
                  patDetails.deviceId + '/' + TOKEN
                )
              );
            }
          }}
        >
          <BIcon
            icon={ICONS['ECG_Viewer']}
            fontSize='large'
            class='patient-list-icons'
          />
        </span>
      ) : null}

      {/* Provision for reports */}
      {reports &&
        reports.map(reportItem => {
          return (
            <span
              event-type={reportItem.type + '_' + reportItem.currentState}
              key={reportItem.type + '_' + reportItem.currentState}
            >
              <BIcon
                title={
                  getReadableReportName(
                    `${reportItem.type} ${reportItem.currentState}`
                  ) +
                  getPrettyDate(
                    reportItem.createdAt,
                    DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
                    true
                  )
                }
                icon={ICONS[reportItem.type + '_' + reportItem.currentState]}
                fontSize='large'
                class='patient-list-icons'
              />
            </span>
          );
        })}
      {patDetails?.deviceLocked ? (
        <span event-type={'lock'}>
          <BIcon
            title={getPrettyDate(
              patDetails.lockedTime,
              DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
              true
            )}
            id={generateID(
              'link',
              'study_lock',
              `${params.row.deviceSettingId}_${params.row.endTime}`
            )}
            icon={ICONS['LOCK']}
            fontSize='large'
            class='patient-list-icons'
          />
        </span>
      ) : null}
    </>
  );
};

/************************** Prescribing Provider **************************/
const PLPrescribingProvider = ({ params }) => {
  return (
    <Typography noWrap component='span' variant='body1'>
      {params.row.prescribingProvider}
    </Typography>
  );
};

/************************** Date of Birth **************************/
const PLDateOfBirth = ({ params }) => {
  return (
    <Typography component='span' variant='body1'>
      {getPrettyDate(params.row.dateOfBirth, DD_MMM_C_YYYY)}
    </Typography>
  );
};

/************************** Notification Date***********************/
const PLNotificationDate = ({ notification_date }) => {
  return (
    <Typography component='span' variant='body1'>
      {getPrettyDate(
        notification_date,
        DF_DD_MMM_YYYY_SHSH_COL_SMSM_COL_SSSS_SA,
        true
      )}
    </Typography>
  );
};

/************************** Organization **************************/
const PLOrganization = ({ params }) => {
  return (
    <Typography component='span' variant='body1'>
      {params.row.orgContactDetails.orgName}
    </Typography>
  );
};

/************************** Status ********************************/
export const PLDeviceEventGroupStatus = ({ current_status }) => {
  const patDetails = useContext(PatientListCellContentContext);
  if (!patDetails) return <>Null patDetails</>;
  if (!current_status) return <>Null current_status parameter</>;
  if (!patDetails.device_event_group_status_types) return <>Null options</>;
  if (patDetails.device_event_group_status_types.loading) return <>Loading</>;
  if (!patDetails.device_event_group_status_types.data) return <>Null data</>;
  if (patDetails.device_event_group_status_types.data.length == 0)
    return <>Zero options</>;

  const item =
    patDetails.device_event_group_status_types.data.deviceEventGroupStatusTypes.find(
      option => option.id === current_status
    );
  if (!item) return <>Can't find item {current_status}</>;
  if (!item.name) return <>No item name</>;
  let displayText: string = item.name;
  if (displayText === 'Done') displayText = 'No Report';
  let iconName: string = item.name;
  if (iconName === 'Done') iconName = 'No Report';

  return (
    <>
      <img
        id={'icon' + displayText}
        src={ICONS[Device_Event_Group_Status[iconName]]}
        alt={displayText}
        title={displayText}
      />
      <>&nbsp;&nbsp;</>
      <Typography
        component='span'
        variant='body1'
        className={`EventStatus ${item.name}`}
      >
        {displayText}
      </Typography>
    </>
  );
};

/************************** Application **************************/
const PLApplication = ({ params }) => {
  let application = params.row?.application;
  return (
    <Typography component='span' variant='body1'>
      {application ? MAP_PATIENT_EVENT_LIST_COL_FILTERS[application] : ''}
    </Typography>
  );
};

export const getPatientListColDetails = (colType, params) => {
  switch (colType) {
    case 'name': {
      return <PLPatientName params={params} />;
    }
    case 'patient_identifier': {
      return <PLPatientIdMRN params={params} />;
    }
    case 'tevent_date': {
      return <PLEventDate params={params} />;
    }
    case 'tevents': {
      return <PLEvents params={params} />;
    }
    case 'tdevice_status': {
      return <PLActivationStatus params={params} />;
    }
    case 'tviewer': {
      return <PLViewer params={params} />;
    }
    case 'history': {
      return <PLHistory params={params} />;
    }
    case 'age_sex': {
      return <PLAgeSex params={params} />;
    }
    case 'indications': {
      return <PLIndications params={params} />;
    }
    case 'tmct_ID': {
      return <PLDeviceId params={params} />;
    }
    case 'prescribing_provider': {
      return <PLPrescribingProvider params={params} />;
    }
    case 'date_of_birth': {
      return <PLDateOfBirth params={params} />;
    }
    case 'notification_date': {
      return (
        <PLNotificationDate notification_date={params.row.notification_date} />
      );
    }
    case 'current_status': {
      return (
        <PLDeviceEventGroupStatus current_status={params.row.current_status} />
      );
    }
    case 'technician_notes': {
      return <PLTechnicianNotes params={params} />;
    }
    case 'tviewer_due': {
      return <PLReportDue params={params} />;
    }
    case 'organization': {
      return <PLOrganization params={params} />;
    }
    case 'application': {
      return <PLApplication params={params} />;
    }
    default: {
      return null;
    }
  }
};
