export const MAP_PATIENT_EVENT_LIST_COL_FILTERS = {
  Critical: 'Critical',
  New: 'New',
  Review: 'Review',
  Published: 'Published',
  Done: 'No Report',
  AF: 'AF',
  VT: 'VT',
  P: 'P',
  T: 'T',
  B: 'B',
  PATIENT_EVENTS: 'Patient Events',
  Active: 'Active',
  battery_level: 'Active + Change Patch',
  'Active + Change Patch': 'Active + Change Patch', // fixes empty bubbles
  data_transmission: 'Active + No Data',
  'Active + No Data': 'Active + No Data', // fixes empty bubbles
  data_transmission_battery_level: 'Active + No Data + Change Patch',
  'Active + No Data + Change Patch': 'Active + No Data + Change Patch', // same
  Incomplete: 'Incomplete',
  Pending: 'Pending',
  Deactivated: 'Deactivated',
  periodic_summarydue: 'Periodic Report Due',
  eosdue: 'EOS Summary Report Due',
  periodic_summaryready_for_review: 'Periodic Report CC Review',
  eosready_for_review: 'EOS Summary Report CC Review',
  event: 'Event Reports',
  periodic_summary: 'Periodic Summary Reports',
  eos_cemready_for_review: 'EOS Summary Reports Ready for Review',
  eos_cemaccepted: 'EOS Summary Reports Approved',
  'mail-to-patient': 'Mail to Patient',
  clinic: 'At Clinic',
};

export const FILTER_EVENTS = {
  current_status: [
    { value: 'Critical', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Critical },
    { value: 'New', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.New },
    { value: 'Review', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Review },
    { value: 'Published', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Published },
    { value: 'Done', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Done },
  ],
  tevents: [
    { value: 'AF', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.AF },
    { value: 'VT', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.VT },
    { value: 'P', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.P },
    { value: 'T', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.T },
    { value: 'B', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.B },
    {
      value: 'PATIENT_EVENTS',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.PATIENT_EVENTS,
    },
  ],
  tdevice_status: [
    { value: 'Active', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Active },
    {
      value: 'Active + Change Patch',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.battery_level,
    },
    {
      value: 'Active + No Data',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.data_transmission,
    },
    {
      value: 'Active + No Data + Change Patch',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.data_transmission_battery_level,
    },
    {
      value: 'Deactivated',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Deactivated,
    },
    {
      value: 'Incomplete',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Incomplete,
    },
    { value: 'Pending', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.Pending },
  ],
  tviewer_due: [
    {
      value: ['periodic_summary', 'due'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.periodic_summarydue,
    },
    { value: ['eos', 'due'], label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eosdue },
    {
      value: ['periodic_summary', 'ready_for_review'],
      label:
        MAP_PATIENT_EVENT_LIST_COL_FILTERS.periodic_summaryready_for_review,
    },
    {
      value: ['eos', 'ready_for_review'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eosready_for_review,
    },
  ],
  history: [
    { value: ['event', ''], label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.event },
    {
      value: ['periodic_summary', ''],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.periodic_summary,
    },
    {
      value: ['eos,cem', 'ready_for_review'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eos_cemready_for_review,
    },
    {
      value: ['eos,cem', 'accepted'],
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS.eos_cemaccepted,
    },
  ],
  application: [
    { value: 'clinic', label: MAP_PATIENT_EVENT_LIST_COL_FILTERS['clinic'] },
    {
      value: 'mail-to-patient',
      label: MAP_PATIENT_EVENT_LIST_COL_FILTERS['mail-to-patient'],
    },
  ],
};

export const MAP_PATIENT_EVENT_LIST_COL_LABELS = {
  current_status: 'Status',
  name: 'Patient Name',
  date_of_birth: 'Date of Birth',
  patient_identifier: 'Patient Identifier',
  prescribing_provider: 'Prescribing Provider',
  technician_notes: 'Technician Notes',
  tevent_date: 'Event Date',
  notification_date: 'Notification Date',
  tevents: 'Clinical Events',
  tdevice_status: 'Device Status',
  tviewer: 'Viewer',
  tviewer_due: 'Viewer / Due',
  history: 'Reports',
  age_sex: 'Age / Sex',
  indications: 'Indications',
  tmct_ID: 'Device SN',
  organization: 'Organization',
  application: 'Application',
};

export const PORTALS = [
  {
    id: 'CAM',
    value: 'cam',
    label: 'CAM Portal',
    redirect: 'https://bdxc-web-test2.azurewebsites.net',
  },
  { id: 'BDM', value: 'bdm_web', label: 'CAM DXT Portal', redirect: '/' },
];
